import React from "react"
import Layout from "../components/layout/layout"
import "./mystyles.scss"
import { Helmet } from "react-helmet"

export default function Contact({ location }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact</title>
      </Helmet>
      <section className="hero is-dark is-bold">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Contact</h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          Pour toutes informations, vous pouvez joindre notre secrétariat à
          l'adresse mail suivante :{" "}
          <a href="mailto:secretaire@team-kangourou.ch">
            secretaire@team-kangourou.ch
          </a>
          .
        </div>
      </section>
    </Layout>
  )
}
